import {NavigationItemWithActive} from '../app/shared/interfaces/navigation-item-with-active.interface';

/**
 * Configuration that is consistent across environments
 */
export const STATIC_CONFIG = {
  displayName: 'JMS',
  elements: {

    /**
     * This is removed from the components selector
     * when registering it as an element
     */
    componentPrefix: 'jms-',

    /**
     * This is added as the suffix instead
     *
     * @example
     * A component with the selector jms-table
     * is registered as an element with the selector
     * jms-e-table
     */
    selectorPrefix: 'jms-e-',
  },
  login: {
    email: true,
    google: true,
    facebook: true
  },
  /**
   * We use this to redirect all unauthenticated users
   */
  loginRoute: ['/login'],
  /**
   * Used for redirecting all authenticated users
   * visiting pages for unauthenticated users
   */
  dashboardRoute: ['/dashboard'],
  navigation: {
    items: [
      {
        icon: 'dashboard',
        label: 'Nadzorna ploča',
        type: 'link',
        value: '/dashboard'
      },
      {
        icon: 'payments',
        label: 'Kupovine',
        type: 'link',
        value: '/m/orders'
      },
      {
        icon: 'supervised_user_circle',
        label: 'Korisnici',
        type: 'link',
        value: '/m/users'
      },
      {
        icon: 'support_agent',
        label: 'Vozači',
        type: 'link',
        value: '/m/drivers'
      },
      {
        icon: 'badge',
        label: 'Tvrtke',
        type: 'link',
        value: '/m/companies'
      },
      {
        icon: 'inventory',
        label: 'Paketi',
        type: 'link',
        value: '/m/packages'
      },
      {
        icon: 'euro_symbol',
        label: 'Usluge',
        type: 'link',
        value: '/m/services'
      },
      {
        icon: 'bluetooth_drive',
        label: 'Vozila',
        type: 'link',
        value: '/m/vehicles'
      },
      {
        icon: 'quiz',
        label: 'FAQ',
        type: 'link',
        value: '/m/faq'
      },
      {
        children: [
          {
            icon: 'send',
            label: 'Pozivi',
            type: 'link',
            value: '/m/user-invites'
          },
          {
            icon: 'vpn_key',
            label: 'Uloge',
            type: 'link',
            value: '/m/roles'
          },
          {
            icon: 'email',
            label: 'Automatski mailovi',
            type: 'link',
            value: '/m/automatic-emails'
           },
           {
             icon: 'send',
             label: 'Poslani mailovi',
             type: 'link',
             value: '/m/sent-emails'
           },
          {
            icon: 'pending',
            label: 'Lista čekanja',
            type: 'link',
            value: '/m/application-waitlist'
          },
          {
            icon: 'send',
            label: 'Upiti za osiguranje',
            type: 'link',
            value: '/m/insurance-requests'
          },
          {
            icon: 'phone',
            label: 'Primljene poruke',
            type: 'link',
            value: '/m/contacts'
          }
        ],
        icon: 'dns',
        label: 'Sustav',
        type: 'expandable'
      }
    ] as NavigationItemWithActive[]
  }
};
