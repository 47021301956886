/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "jp-jurilica",
    "appId": "1:588981950067:web:22f4f45360bfc1a8fa7c06",
    "storageBucket": "jp-jurilica.appspot.com",
    "apiKey": "AIzaSyCBZkBkG45_DQxocWqLgyX9qKiCeV5lmiw",
    "authDomain": "jp-jurilica.firebaseapp.com",
    "messagingSenderId": "588981950067",
    "measurementId": "G-P0FDYEVM3N"
  }
};
